@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-grey: 229, 228, 226;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgba: 242, 245, 249, 1;
  }
}

body {
  color: rgb(var(--foreground-rgba));
  background-color: rgb(var(--background-grey));
  font-family: Arial, sans-serif;
  /* margin: 20px; */
}
.page-header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
}

.logo-container {
  margin-right: 20px;
}

.logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-title {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #000;
}

.subtitle {
  margin: 5px 0 0;
  font-size: 14px;
  color: #888;
}

h1 {
  font-size: 24px;
  text-align: center;
}

input[type="file"] {
  display: block;
  margin: 20px auto;
}

svg {
  display: block;
  margin: 20px auto;
}


.tableMargin {
  margin-right: 20px;
}

.container {
  display: flex;
  gap: 8px; 
  align-items: flex-start; 
}

.text {
  flex: 1;
}

.card-container {
  display: flex;
  flex-wrap: wrap; 
  gap: 1px;
}

/*
.card {
  background-color: rgb(241, 245, 249);
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 180px;
  height: 90px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
*/

h3 {
  font-size: 12px;
  margin: 0;
}

/* p {
  margin: 10px;
} */
