@import url('https://fonts.googleapis.com/css?family=Play:400,700');

:root {
  --foreground-rgb: 0, 0, 0;
  --background-grey: 229, 228, 226;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgba: 242, 245, 249, 1;
  }
}

body {
    
  color: rgb(var(--foreground-rgba));
  background-color: rgb(var(--background-grey));
  /* background-image: url('Untitled.png'); */
  /* background-color: #292C35; */
  font-family: Arial, sans-serif;
  /* margin: 20px; */
}

* {
  box-sizing: border-box;
}

h1 {
  font-size: 2rem;
  color: #FFF;
  text-align: center;
  text-transform: uppercase;
}

.loading-container{
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 288px;
  height: 388px;
}

.respirer {
  background: url('respirer.png') no-repeat center center;
  background-size: contain;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}

.logo {
  width: 288px;
  height: 288px;
  position: relative;
}

.circle {
  padding: 20px;
  border: 6px solid transparent;
  border-top-color: #62C370;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: connect 2.5s linear infinite;
}



@keyframes connect {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
